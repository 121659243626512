import { ActionIcon, Box, Button, Grid, Group, Select, Tooltip } from "@mantine/core";
import { IconCheck, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import Modal, { Props as ModalProps } from "./Modal";
import { AllLeagues, useAddParticipatingPlayersMatch, useAllParticipatingPlayers, useUpdateSub } from "../../graphql";
import { Notyf } from "notyf";

const { Col } = Grid;

type Props = {
    setSelectedData?: (id: string) => void;
    dataMatch?: any;
    data?: any;
} & ModalProps;

export const AddSubstitionMatchs = ({ data, dataMatch, ...props }: Props) => {
    const { getInputProps, reset, onSubmit, values, insertListItem, removeListItem } = useForm({
        initialValues: {
            players: []
        }
    });
    const [createParticipatingPlayersMatch] = useAddParticipatingPlayersMatch();
    const [allTeams, setAllTeams] = useState<{ label: string, value: string }[]>([]);
    const [allPlayersstarter, setAllPlayersstarter] = useState<{ label: string, value: string }[]>([]);
    const [allPlayersstartersub, setAllPlayersstartersub] = useState<{ label: string, value: string }[]>([]);
    const [participatingTeam, setParticipatingTeam] = useState<string | null>("");

    const [updateSub] = useUpdateSub();
    const [getAllPlayersMatch] = useAllParticipatingPlayers();

    useEffect(() => {
        if (data !== null && props.opened) {
            let newAllTeams: { label: string, value: string }[] = []

            const participatingTeams = data?.participatingTeams

            for (let i = 0; i < participatingTeams.length; i++) {
                const item = participatingTeams[i]

                if (item?.id === dataMatch?.firstTeam?.id || item?.id === dataMatch?.secondTeam?.id) {
                    newAllTeams.push({ value: item.id, label: `${item?.team?.name} - ${item?.group}` })
                }
            }
            setAllTeams([...newAllTeams])

        }
    }, [data, dataMatch, props.opened])

    useEffect(() => {
        if (props.opened) {
            getAllPlayersMatch({
                variables: {
                    idParticipatingTeams: participatingTeam
                },
                onCompleted: ({ allParticipatingPlayers }) => {
                    let newAllPlayersStart: { label: string, value: string }[] = []
                    let newAllPlayerssub: { label: string, value: string }[] = []
                    for (let i = 0; i < allParticipatingPlayers.length; i++) {
                        const item = allParticipatingPlayers[i]

                        for (let j = 0; j < item?.participatingPlayersMatches.length; j++) {
                            const matchPlayer = item?.participatingPlayersMatches[j]
                            if (matchPlayer?.id_match?.id === dataMatch?.id) {
                                if (matchPlayer?.starter === true) {
                                    newAllPlayersStart.push({
                                        value: matchPlayer.id,
                                        label: `(${item?.number}) ${item?.player?.person?.first_name} ${item?.player?.person?.second_name} ${item?.player?.person?.third_name} ${item?.player?.person?.tribe}`
                                    })
                                } else {
                                    newAllPlayerssub.push({
                                        value: matchPlayer.id,
                                        label: `(${item?.number}) ${item?.player?.person?.first_name} ${item?.player?.person?.second_name} ${item?.player?.person?.third_name} ${item?.player?.person?.tribe}`
                                    })
                                }
                            }
                        }
                    }
                    setAllPlayersstarter([...newAllPlayersStart])
                    setAllPlayersstartersub([...newAllPlayerssub])
                }
            })
        }
    }, [participatingTeam, dataMatch, props.opened])

    const onFormSubmit = ({ players }: any) => {
        const notyf = new Notyf({ position: { x: 'right', y: 'bottom' } });
        console.log(players)
        let allSub : string[]= []
        for(let i=0;i<players.length;i++){
            allSub.push(players[i].starter_player)
            allSub.push(players[i].substitute_player)
        
        }
        
        let promises = allSub.map((player: any) => {
            console.log(player)
            return updateSub({
                variables: {
                    id: player, // Assuming this is the correct ID for the player
                    sub: true
                },
                onError: ({ graphQLErrors }) => {
                    console.log(false);
                }
            });
           
        
        
        
        });

        Promise.all(promises)
            .then(() => {
                closeModal();
                notyf.success('تم اضافة القائمة');
            })
            .catch((error) => {
                console.error('Error updating players:', error);
            });
    };

    const addItem = () => {
        insertListItem('players', {
            starter_player: "", // Adjust property names based on your data structure
            substitute_player: "", // Adjust property names based on your data structure
            id_participating_team: participatingTeam,
            number: ""
        });
    };

    const removeItem = (index: number) => {
        removeListItem('players', index);
    };

    const closeModal = () => {
        props.onClose();
        reset();
        setAllTeams([]);
        setParticipatingTeam(null);
    };

    return (
        <Modal
            size={"60%"}
            {...props}
            onClose={closeModal}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"left"} gap={"xs"}>
                        <Button variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>إلغاء</Button>
                        <Button rightSection={<IconCheck size={15} />} type="submit" form="submit_form">تأكيد</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{ padding: 20 }}>
                <form onSubmit={onSubmit(onFormSubmit)} id="submit_form">
                    <Grid gutter={40}>
                        <Col span={12}>
                            <Group wrap={"nowrap"} align={"flex-end"}>
                                <Select
                                    label={`اسم الفريق`}
                                    placeholder="اختر الفريق"
                                    withAsterisk
                                    data={allTeams}
                                    value={participatingTeam}
                                    onChange={setParticipatingTeam}
                                    style={{ width: "100%" }}
                                />

                                <Tooltip label={"اضافة لاعب"}>
                                    <ActionIcon size={26} variant={"filled"} color={"teal"} onClick={addItem}>
                                        <IconPlus size="1.125rem" />
                                    </ActionIcon>
                                </Tooltip>
                            </Group>
                        </Col>

                        {values.players.map((item, index) => (
                            <Col span={12} key={index}>
                                <Group wrap={"nowrap"} justify={"space-between"} align={"flex-end"}>
                                    <Grid gutter={20} style={{ width: "100%" }}>
                                        <Col span={6}>
                                            <Select
                                                label={`اساسي ${index + 1}`}
                                                placeholder="اساسي"
                                                withAsterisk
                                                data={allPlayersstarter}
                                                {...getInputProps(`players.${index}.starter_player`)}
                                                style={{ width: "100%" }}
                                            />
                                        </Col>

                                        <Col span={6}>
                                            <Select
                                                label={`احتياط ${index + 1}`}
                                                placeholder="احتياط"
                                                withAsterisk
                                                data={allPlayersstartersub}
                                                {...getInputProps(`players.${index}.substitute_player`)}
                                                style={{ width: "100%" }}
                                            />
                                        </Col>
                                    </Grid>

                                    <Tooltip label={"حذف لاعب"}>
                                        <ActionIcon size={36} variant={"filled"} color={"red"} onClick={() => removeItem(index)}>
                                            <IconTrash size="1.125rem" />
                                        </ActionIcon>
                                    </Tooltip>
                                </Group>
                            </Col>
                        ))}
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};
